@import '../../globals/scss/variables';

.library-add-button {
    display: block;
    text-align: center;
    line-height: 4em;
    &:hover {
        color: #fff;
    }
}

.button-menu {
    position: absolute;
    bottom: em(80);
    right: em(80);
    display: flex;
    &__item {
        margin-right: em(15);
        &:last-child {
            margin-right: 0;
        }
    }
}