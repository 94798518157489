/* main-colors */
$primary-color: #3AC7B9;

/* additional colors */
$green: #28A645;
$blue: #54A3FF;
$blue-darker: #4C637F;
$grey: #939393;
$light-grey: lighten($grey, 20%);
$red: #AC0013;

/* font-colors */
$primary-font-color: #333;

/* font-family */
$primary-font-family: 'Roboto', sans-serif;

/* additonal variables */ 
$box-shadow-button:  0px 3px 10px rgba(#000, 0.1);

@import 'mixins';
@import 'functions';